<template>
    <Dialog modal="true" v-model:visible="visivel" :style="{width: '45%'}" @hide="fechar()">
        <template #header>
          <div class="flex align-items-center">
            <i class="pi pi-filter mr-1" style="font-size: 1.4rem"></i>
            <h3 style="margin:0px 5px;">Filtro avançado</h3>
          </div>
        </template>

                <div class="grid p-fluid formgrid align-items-center">
                    <div class="col-12 lg:col-3">
                        <div class="field">
                            <label for="nr-ci">
                                <strong>Contrato Interno</strong>
                            </label>
                            <InputNumber id="nr-ci" class="w-full" v-model="filtros.idContrato" placeholder="Nº contrato"/>
                        </div>
                    </div>

                    <div class="col-12 lg:col-3">
                      <div class="field">
                        <label>
                          <strong>Período Embarque</strong>
                        </label>

                        <div class="flex align-items-center">
                          <div class="overflow-hidden" style="width:13rem;">
                            <Calendar
                                class="w-full"
                                v-model="periodoInicio"
                                autocomplete="off"
                                dateFormat="dd/mm/y"
                                :maxDate="periodoFim"
                            />
                          </div>

                          <strong class="ml-2 mr-2">à</strong>

                          <div class="overflow-hidden" style="width:13rem;">
                            <div class="p-inputgroup">
                              <Calendar
                                  class="w-full"
                                  v-model="periodoFim"
                                  autocomplete="off"
                                  dateFormat="dd/mm/y"
                                  :minDate="periodoInicio"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 lg:col-3">
                      <div class="field">
                        <label for="nr-ci">
                          <strong>Contrato Jurídico</strong>
                        </label>
                        <InputText id="nr-cj" class="w-full" v-model="filtros.contratoJuridico" placeholder="Nº contrato"/>
                      </div>
                    </div>

                    <div class="col-12 lg:col-3" >
                      <div class="field">
                      <label for="estabelecimento">
                        <strong>Produto</strong>
                      </label>
                      <AutoComplete
                          dropdown
                          placeholder="Todos"
                          v-model="tipoProdutoSelecionado"
                          field="nome"
                          id="produto"
                          class="w-full"
                          :suggestions="tipoProdutoFiltro"
                          :forceSelection="true"
                          @complete="buscarTipoProduto"
                      />
                      </div>
                    </div>

                    <div class="col-12 lg:col-3">
                      <div class="field">
                        <label for="nr-contrato-rep">
                          <strong>Contrato Corretora</strong>
                        </label>
                        <InputText id="nr-contrato-rep" class="w-full" v-model="filtros.contratoCorretora" placeholder="Nº contrato"/>
                      </div>
                    </div>

                    <div class="col-12 lg:col-9">
                        <div class="field">
                            <label>
                                <strong>Corretora</strong>
                            </label>
                            <CorretoraZoom
                                codigo="codigoCliente"
                                descricao="nome"
                                :selecionado="corretoraSelecionada"
                                :disabled="false"
                                :paginacao="corretoraConsulta"
                                @pesquisar="pesquisarCorretora"
                                @selecionado="selecionarCorretora"
                            />
                        </div>
                    </div>

                    <div class="col-12 lg:col-12">
                        <div class="field">
                            <label>
                                <strong>Comprador</strong>
                            </label>
                            <ProdutorFiltro
                                tituloModal="Buscar Comprador"
                                :disabled="false"
                                :campoObrigatorio="true"
                                :selecionado="filtros.compradorSelecionado"
                                :paginacao="compradorConsulta"
                                @pesquisar="pesquisarComprador"
                                :tela="'contratoFiltro'"
                                @selecionado="selecionarComprador"
                            />
                        </div>
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                          <div style="width:39.6rem"></div>
                          <Button
                              icon="pi pi-search"
                              label="Filtrar"
                              class="p-button-outlined"
                              autofocus
                              @click="filtrar"
                          ></Button>
                          <Button
                              label="Limpar"
                              icon="pi pi-trash"
                              @click="limpar"
                          ></Button>
                        </div>
                    </div>

                </div>

    </Dialog>
</template>

<script>
import CorretoraZoom from './CorretoraZoom.vue';
import Formatacao from '../../utilities/Formatacao';
import ProdutorFiltro from '../cadastro/ProdutorFiltro.vue';
import CorretoraService from '../../service/CorretoraService';
import ProdutorService from '../../service/ProdutorService';
import TipoProdutoService from "@/service/TipoProdutoService";

export default {
    components: { CorretoraZoom, ProdutorFiltro },

    emits: ["filtrar"],

    props: {
      visivel:{
        type: Boolean,
        required: true
      },
      filter:{
        type: Object,
        required: true
      }
    },

    data() {
        return {
            filtros: this.filter,

            totalRegistro: 0,
            filtroAvancado: false,
            selecionadoNaListagem: null,

            periodoInicio: null,
            periodoFim: null,
            dataContrato: null,
            corretoraSelecionada: {},
            compradorSelecionado: {},
            compradorConsulta: {},
            corretoraConsulta: {},
            tipoProdutoSelecionado: null,
            tipoProdutoFiltro: []

        }
    },

    updated() {
      if(!this.filtros.idCorretora && !this.filtros.corretoraId){this.corretoraSelecionada={};}
      if(!this.filtros.idProduto && !this.filtros.produtoId){this.tipoProdutoSelecionado=null;}
      if(!this.filtros.periodoInicio){this.periodoInicio=null;this.periodoFim=null;}
    },

    methods: {

        buscarTipoProduto(event) {
          const filtro = !event.query.trim().length ? undefined : event.query.trim();

          TipoProdutoService.buscarPorNomeComPaginacao(filtro, 0, 50)
              .then(({ data }) => {
                if(!!data) {
                  this.tipoProdutoFiltro = data.content;
                  this.tipoProdutoFiltro.unshift({nome: "Todos"});

                }
              })
              .catch(error => {
                const toast = {
                  severity: 'error',
                  summary: 'Falha ao carregar os tipos de produto.',
                  detail: error,
                  life: 7500
                }

                if(!!error && !!error.response && !!error.response.data) {
                  const { message } = error.response.data;
                  toast["detail"] = message;
                }

                this.$toast.add(toast);
              });
        },

        fechar(){
          this.$emit("filtrar", null);
        },

        filtrar() {
          const filtros = this.getFiltros();
          this.$emit("filtrar", filtros);
        },

        getFiltros() {
          const filtros = Object.assign({}, this.filtros);

          if(!!this.tipoProdutoSelecionado) {
            filtros.idProduto = this.tipoProdutoSelecionado.id;
          } else {
            filtros.idProduto = undefined;
          }

          if(!!this.periodoInicio) {
            filtros.periodoInicio = Formatacao.formatDateCustom(this.periodoInicio, 'YYYY-MM-DD');
          }
          else {
            filtros.periodoInicio = undefined;
          }

          if(!!this.periodoFim) {
            filtros.periodoFim = Formatacao.formatDateCustom(this.periodoFim, 'YYYY-MM-DD');
          }
          else {
            filtros.periodoFim = undefined;
          }

          if(!!this.corretoraSelecionada && !!this.corretoraSelecionada.id) {
            filtros.corretoraId = this.corretoraSelecionada.id;
          } else {
            filtros.corretoraId = undefined;
          }

          return filtros;
        },

        limpar() {
            this.filtros["idProduto"] = undefined;
            this.filtros.periodoInicio = undefined;
            this.filtros.periodoFim = undefined;
            this.filtros.corretoraId = undefined;
            this.filtros["compradorSelecionado"] = {codProduto:""};
            this.filtros.contratoCorretora = undefined;
            this.filtros.idContrato = undefined;
            this.filtros.contratoJuridico = undefined;
            this.tipoProdutoSelecionado = undefined;
            this.compradorSelecionado = {};
            this.corretoraSelecionada = {};

            this.periodoInicio = null;
            this.periodoFim = null;
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },
        
        pesquisarCorretora(filtros) {
            CorretoraService.buscarComPaginacao(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.corretoraConsulta = data;
                    }
                })
                .catch(error => {
                    this.corretoraConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar as corretoras.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarCorretora(codigo) {
            if(codigo == undefined || codigo == null || codigo.trim() == "") {
                this.corretoraSelecionada = {};
                return;
            }

            CorretoraService.buscarPorCodigoCliente(codigo)
                .then(({ data }) => {
                    this.corretoraSelecionada = data;
                })
                .catch(error => {
                    this.corretoraSelecionada = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar corretora.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!", 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if(!!message) {
                            toast["detail"] = message;
                            toast["severity"] = 'warn';
                        }
                    }

                    this.$toast.add(toast);
                });
        },

        pesquisarComprador(filtros) {
            ProdutorService.pesquisarProdutor(filtros)
                .then(({ data }) => {
                    if(data) {
                        this.compradorConsulta = data;
                    }
                })
                .catch(error => {
                    this.compradorConsulta = {}

                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao carregar os compradores.',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        },

        selecionarComprador(codProdutor) {
            if(codProdutor == undefined || codProdutor == null || codProdutor.trim() == "") {
                this.compradorSelecionado = {};
                this.filtros["compradorSelecionado"] = {};
                return;
            }

            ProdutorService.buscarPorCodProdutorInfoContrato(codProdutor)
                .then(({ data }) => {
                    if(data) {
                        this.compradorSelecionado = data;
                        this.filtros["compradorSelecionado"] = data;
                    }
                })
                .catch(error => {
                    this.compradorSelecionado = {};
                    this.filtros["compradorSelecionado"] = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar o comprador.`,
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        if(!!message) {
                            toast["detail"] = message.replace("produtor", "comprador");
                        }
                        toast["severity"] = 'warn';
                    }

                    this.$toast.add(toast);
                });
        }
    }
}
</script>